import "../styles/pages/page-contest-terms.scss";

import React from "react";
import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";
import Breadcrumbs from "../components/Breadcrumbs";

const ContestTerms = () => {
  return (
    <Layout headerColor="#9300B9">
      <Seo title="Regulamin konkursu" />
      <SubpageHeader
        image={require("assets/images/kontakt-bg-main.jpg").default}
      />
      <Breadcrumbs currentLocationLabel="Regulamin konkursu" />;
      <section className="contest-terms">
        <div className="container">
          <div className="contest-terms__header">
            <h1>REGULAMIN KONKURSU</h1>
            <span>
              „Jak nazywa się ta atrakcja i w którym mieście w Tajlandii się
              znajduje?”
            </span>
          </div>
          <div className="contest-terms__content">
            <ul>
              <li>1. DEFINICJE</li>
              <ul>
                <li>
                  <p>Ilekroć w niniejszym regulaminie jest mowa o:</p>
                  <li>
                    1.1. „Regulaminie” – należy przez to rozumieć niniejszy
                    regulamin;
                  </li>
                  <li>
                    1.2. „Organizatorze” – należy przez to rozumieć spółkę WHEN
                    sp. z o.o. z siedzibą w Krakowie (ul. Chopina 18/3a, 30-047
                    Kraków) wpisaną do rejestru przedsiębiorców Krajowego
                    Rejestru Sądowego pod numerem 0000683438, o kapitale
                    zakładowym 10.000,00 zł, posiadającą NIP: 6772421673 i numer
                    REGON: 367571758, adres e-mail: biuro@when.pl;
                  </li>
                  <li>
                    1.3. „Fundatorze” – należy przez to rozumieć Tourism
                    Authority of Thailand z siedzibą w Pradze ( Purkyńova
                    2121/3, 110 00 Prague 1, Czech Republic). Business
                    Identification Number: 04863852
                  </li>
                  <li>
                    1.4. „Konkursie” – należy przez to rozumieć konkurs
                    przeprowadzany przez Organizatora pod nazwą „Jak nazywa się
                    ta atrakcja i w którym mieście w Tajlandii się znajduje?”
                    opisany w Regulaminie;
                  </li>
                  <li>
                    1.5. „Uczestniku” – należy przez to rozumieć osobę fizyczną,
                    która najpóźniej w chwili przystąpienia do Konkursu
                    ukończyła 18 lat, posiadającą pełną zdolność do czynności
                    prawnych i miejsce zamieszkania oraz adres do doręczeń na
                    terytorium Rzeczypospolitej Polskiej, posiadająca aktywne
                    konto w serwisie Facebook, która wykona Zadanie Konkursowe;
                  </li>
                  <li>
                    1.6. „Jury” – należy przez to rozumieć komisję, o której
                    mowa w pkt. 6.1. Regulaminu;
                  </li>
                  <li>
                    1.7. „Zadaniu Konkursowym” – należy przez to rozumieć
                    ogłoszone przez Organizatora zadanie do wykonania przez
                    Uczestnika na zasadach określonych w Regulaminie;
                  </li>
                  <li>
                    1.8. „Zgłoszeniu Konkursowym” – należy przez to rozumieć
                    dzieło stworzone w wykonaniu Zadania Konkursowego i
                    udostępnione na zasadach, w terminie i w formie wskazanej w
                    Regulaminie;
                  </li>
                  <li>
                    1.9. „Facebooku” – należy przez to rozumieć, zależnie od
                    kontekstu, Facebook Ireland Ltd. – spółkę zarejestrowaną w
                    Dublinie w Irlandii lub prowadzony przez nią portal
                    społecznościowy Facebook;
                  </li>
                  <li>
                    1.10. „Stronie Facebook Fundatora”– należy przez to rozumieć
                    oficjalny profil Fundatora prowadzony w serwisie Facebook
                    dostępny pod adresem:{" "}
                    <a
                      href="https://www.facebook.com/AmazingThailandPoland/"
                      target="_blank"
                    >
                      https://www.facebook.com/AmazingThailandPoland/
                    </a>
                  </li>
                  <li>
                    1.12. „Nagrodzie” - należy przez to rozumieć nagrodę
                    rzeczową lub pieniężną przyznaną Zwycięzcy przez Fundatora w
                    formie i o wartości wskazanej w Regulaminie;
                  </li>
                  <li>
                    1.13. „Zwycięzcy” – należy przez to rozumieć Uczestników
                    wybranych przez Jury, którzy nabyli prawo do Nagrody;
                  </li>
                  <li>
                    1.14. „Prawie Autorskim”– należy przez to rozumieć ustawę z
                    dnia 4 lutego 1994 roku o prawie autorskim i prawach
                    pokrewnych (tekst jedn.: Dz.U. z 2018 r. poz. 1191);
                  </li>
                  <li>
                    1.15. „RODO”- należy przez to rozumieć Rozporządzenie
                    Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27
                    kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku
                    z przetwarzaniem danych osobowych i w sprawie swobodnego
                    przepływu takich danych oraz uchylenia dyrektywy 95/46/WE
                    (ogólne rozporządzenie o ochronie danych).
                  </li>
                </li>
              </ul>
              <li>2. POSTANOWIENIA OGÓLNE</li>
              <ul>
                <li>
                  2.1. Regulamin określa wszystkie zasady uczestnictwa w
                  Konkursie, a wszelkie publikowane przez Organizatora materiały
                  promocyjno-reklamowe mają jedynie charakter informacyjny.
                </li>
                <li>
                  2.2. Regulamin jest dostępny w siedzibie Organizatora i
                  Fundatora oraz pod tym linkiem:{" "}
                  <a
                    href="https://www.facebook.com/AmazingThailandPoland/"
                    target="_blank"
                  >
                    https://www.facebook.com/AmazingThailandPoland/
                  </a>
                </li>
                <li>
                  2.3. Konkurs nie jest grą losową w rozumieniu art. 2 ustawy o
                  grach hazardowych z dnia 19 listopada 2009 r. (tj. z dnia 8
                  grudnia 2017 r., Dz.U. z 2018 r. poz. 165).
                </li>
                <li>
                  2.4. Ramy czasowe Konkursu określone są dla czasu
                  środkowoeuropejskiego.
                </li>
                <li>
                  2.5. Konkurs rozpoczyna się w dniu 12.12.2022 roku o 1o:00 i
                  kończy się 19.12.2022 roku o godzinie 23:59.
                </li>
                <li>
                  2.6. W związku z obowiązującym Organizatora regulaminem
                  portalu Facebook Organizator informuje, iż:
                </li>
                <li>
                  2.6.1 Konkurs nie jest w żaden sposób sponsorowany, popierany
                  ani przeprowadzany przez serwis Facebook, ani z nim związany;
                </li>
                <li>
                  2.6.2. serwis Facebook nie ponosi odpowiedzialności w związku
                  z Konkursem.
                </li>
                <li>
                  2.7. Zadanie Konkursowe zostanie opublikowane na profilu
                  Facebook Organizatora oraz Koncie Organizatora nie później niż
                  w terminie wskazanym w 2.5.
                </li>
              </ul>
              <li>3. WARUNKI UCZESTNICTWA W KONKURSIE</li>
              <ul>
                <li>
                  3.1. Uczestnictwo w Konkursie ma charakter dobrowolny i jest
                  wolne od wszelkich opłat.
                </li>
                <li>
                  3.2. Uczestnikiem Konkursu może być wyłącznie pełnoletnia
                  (najpóźniej w chwili złożenia Zgłoszenia Konkursowego) osoba
                  fizyczna, posiadająca pełną zdolność do czynności prawnych,
                  posiadająca aktywne konto w serwisie Facebook, która wykona
                  prawidłowo Zadanie Konkursowe.
                </li>
                <li>
                  3.3. W Konkursie nie mogą brać udziału pracownicy Organizatora
                  i inne osoby współpracujące z Organizatorem przy organizacji
                  Konkursu, członkowie organów zarządzających Organizatora,
                  podmioty i ich pracownicy współpracujący z Organizatorem przy
                  organizowaniu Konkursu oraz członkowie najbliższych rodzin
                  wymienionych osób, to jest małżonkowie, krewni i powinowaci w
                  linii prostej (np. rodzice, dzieci, dziadkowie, wnuki), ich
                  przysposobieni lub przysposabiający, a także rodzeństwo.
                  Powyższe postanowienia stosują się odpowiednio do Fundatora i
                  osób z nim związanych.
                </li>
                <li>
                  3.4. W Konkursie można brać udział wyłącznie osobiście, tj.
                  niedopuszczalne jest tworzenie kont na serwisie Facebook i
                  dokonywanie Zgłoszeń Konkursowych w imieniu osób trzecich.
                </li>
                <li>
                  3.5. Uczestnik przyjmuje do wiadomości, że Konkurs jest
                  organizowany za pośrednictwem portalu społecznościowego
                  Facebook i w związku z tym, z przyczyn niezależnych od
                  Organizatora, zarówno Uczestnik, jak i Organizator podlegają
                  regulacjom obowiązującym na tym portalu.
                </li>
                <li>
                  3.6. Rezygnacja z udziału w Konkursie może nastąpić poprzez
                  usunięcie całości lub części Zgłoszenia Konkursowego przez
                  Uczestnika. W takim wypadku Uczestnik zostaje wykluczony z
                  Konkursu niezależnie od przyczyn takiego usunięcia i
                  tożsamości podmiotu usuwającego.
                </li>
                <li>
                  3.7. Organizator zastrzega sobie prawo do zmiany całości lub
                  części Zadania Konkursowego w dowolnym momencie.
                </li>
              </ul>
              <li>4. PRZEBIEG KONKURSU</li>
              <ul>
                <li>
                  4.1. W trakcie trwania Konkursu Uczestnicy wypełniają Zadanie
                  Konkursowe, a Jury dokonuje wyboru Zwycięzcy.
                </li>
                <li>
                  4.2. Zadanie Konkursowe polega na zamieszczeniu w komentarzu
                  pod wpisem konkursowym autorskiego wierszyka lub wiersza,
                  który odpowiada na pytanie dotyczące opublikowanego zdjęcia we
                  wpisie konkursowym ,,Jak nazywa się ta atrakcja i w którym
                  mieście w Tajlandii się znajduje?”.
                </li>
                <li>
                  4.3. Wysłanie Zgłoszenia Konkursowego jest jednoznaczne z
                  akceptacją Regulaminu.
                </li>
                <li>
                  4.4. Zgłoszenia do Konkursu są dokonywane wyłącznie w formie
                  określonej w pkt. 4.2. powyżej.
                </li>
                <li>
                  4.5. Każdy Uczestnik może wysłać wyłącznie jedno Zgłoszenie
                  Konkursowe.
                </li>
              </ul>
              <li>
                5. PRAWA NA DOBRACH NIEMATERIALNYCH DO ZGŁOSZENIA KONKURSOWEGO
              </li>
              <ul>
                <li>
                  5.1. Zgłaszając się do Konkursu Uczestnik potwierdza, że:
                </li>
                <li>
                  5.1.1. zgłoszone przez niego Zgłoszenie Konkursowe, jest
                  oryginalne, dotychczas niepublikowane i nienagradzane w innych
                  konkursach;
                </li>
                <li>
                  5.1.2. przysługują mu autorskie prawa majątkowe do Zgłoszenia
                  Konkursowego w pełnym zakresie lub jest osobą uprawnioną do
                  dysponowania prawami autorskimi majątkowymi do Zgłoszenia
                  Konkursowego w pełnym zakresie oraz prawa te nie są w żaden
                  sposób ograniczone, lub obciążone na rzecz osób trzecich;
                </li>
                <li>
                  5.1.3. zgłoszenie Konkursowe nie narusza dóbr osobistych, praw
                  autorskich oraz praw własności przemysłowej osób trzecich;
                </li>
                <li>
                  5.1.4. korzystanie ze Zgłoszenia Konkursowego w zakresie
                  koniecznym dla realizacji Konkursu, jak również w zakresie
                  określonym w Regulaminie nie będzie naruszało praw osób
                  trzecich.
                </li>
                <li>
                  5.2. W przypadku jeżeli Zgłoszenie Konkursowe będzie stanowić
                  utwór w rozumieniu Prawa Autorskiego z chwilą wysłania Zadania
                  Konkursowego Uczestnik udziela Fundatorowi niewyłącznej,
                  nieodpłatnej, zbywalnej, nieograniczonej terytorialnie
                  licencji do tego utworu na wszelkich aktualnie znanych polach
                  eksploatacji, w tym określonych w art. 50. i art. 74. Prawa
                  Autorskiego, w tym:
                </li>
                <li>
                  5.2.1. w zakresie przekazywania utworów za pośrednictwem sieci
                  komputerowych, w tym poprzez Internet – w dowolnym
                  standardzie, systemie i formacie (w tym w szczególności
                  poprzez dowolne ich wykorzystywanie na portalach
                  społecznościowych);
                </li>
                <li>
                  5.2.2. w zakresie utrwalania oraz zwielokrotniania utworów –
                  utrwalanie, zwielokrotnianie i wytwarzanie każdą możliwą
                  techniką, w tym techniką drukarską, reprograficzną, zapisu
                  magnetycznego i optycznego, techniką cyfrową, wykonywania
                  odbitek itd., w tym w formie plastycznej, fotograficznej,
                  poligraficznej, zapisu w pamięci komputera lub na nośnikach
                  komputerowych (magnetycznych, optycznych), zapisu
                  audiowizualnego – w dowolnym standardzie, systemie i formacie,
                  w dowolnej liczbie egzemplarzy;
                </li>
                <li>
                  5.2.3. w zakresie obrotu oryginałem lub egzemplarzami –
                  wprowadzanie do obrotu, w tym publiczne wystawianie i
                  udostępnianie za pośrednictwem środków masowego przekazu,
                  egzemplarzy utworów utrwalonych i zwielokrotnionych w sposób
                  określony powyżej, jak również użyczenie lub najem i dzierżawa
                  oryginału, lub egzemplarzy;
                </li>
                <li>
                  5.2.4. w zakresie rozpowszechniania utworów w sposób inny niż
                  określony powyżej – publiczne udostępnianie w szczególności na
                  ogólnodostępnych wystawach, przy prezentacji i reklamie w
                  mediach tradycyjnych i elektronicznych, utrwalanie na
                  nośnikach elektronicznych, publikacja w takich formach
                  wydawniczych jak książki, albumy, broszury, a także
                  wystawienie, wyświetlenie, odtworzenie, nadawanie i
                  remitowanie (w tym także w postaci makiet) oraz publiczne
                  udostępnianie utworów w taki sposób, aby każdy mógł mieć do
                  nich dostęp w miejscu i czasie przez siebie wybranym, oraz
                  wyświetlanie utworów utrwalonych w formie zapisu
                  audiowizualnego;
                </li>
                <li>
                  5.2.5. w zakresie wykonywania wszelkich prac na podstawie
                  utworów, w nieograniczonych ilościach wykorzystań i w
                  dowolnych miejscach;
                </li>
                <li>
                  5.2.6. w zakresie rozporządzania i korzystania z utworów
                  zależnych w stosunku do utworu, w szczególności z wszelkich
                  ich opracowań, adaptacji lub przeróbek;
                </li>
                <li>
                  5.2.7. wykonywanie autorskich praw zależnych do utworu, w tym
                  poprzez włączanie utworu do innych utworów (rozporządzanie i
                  korzystanie z opracowań utworu) oraz na zezwalanie na
                  korzystanie z opracowań Zgłoszenia Konkursowego i
                  rozporządzanie tymi opracowaniami według uznania Fundatora;
                  przy czym licencja udzielana jest na okres 10 (słownie:
                  dziesięciu) lat (licząc od dnia dostarczenia Zgłoszenia
                  Konkursowego) oraz obejmuje prawo do udzielania sublicencji w
                  zakresie pól eksploatacji określonych powyżej.
                </li>
                <li>
                  5.3. Fundator zastrzega na podstawie art. 921 §3 ustawy z dnia
                  23 kwietnia 1964 r. Kodeks cywilny (tj. z dnia 10 maja 2018
                  r., Dz.U. z 2018 r. poz. 1025, dalej), iż z chwilą przekazania
                  Nagrody na warunkach wskazanych w 7.5. nabywa własność
                  Zgłoszenia Konkursowego Zwycięzcy, a Zwycięzca w zamian za
                  przyznanie Nagrody przenosi na Fundatora autorskie prawa
                  majątkowe do Zgłoszenia Konkursowego na polach eksploatacji
                  wskazanych w 5.2. powyżej.
                </li>
                <li>
                  5.4. Z tytułu oświadczeń złożonych w pkt. 5.1. Uczestnik
                  ponosi przed Fundatorem odpowiedzialność gwarancyjną za to, że
                  żadna osoba trzecia nie będzie podnosiła roszczeń wobec
                  Fundatora. W szczególności w przypadku jakichkolwiek roszczeń
                  ze strony osób trzecich Uczestnik, po zawiadomieniu przez
                  Fundatora/ Organizatora, zobowiązuje się zwolnić Fundatora z
                  wszelkiej odpowiedzialności z tego tytułu oraz do całkowitego
                  zaspokojenia uzasadnionych roszczeń osób trzecich, a także
                  wstąpić w miejsce Fundatora w każdym sporze sądowym lub w
                  przypadku braku takiej możliwości — przystąpić po stronie
                  Fundatora do każdego sporu sądowego, a w przypadku zasądzenia
                  jakichkolwiek kwot od Fundatora, Uczestnik zobowiązuje się
                  zwrócić całość pokrytych roszczeń oraz wszelkie związane z tym
                  wydatki i opłaty, włączając w to koszty procesu i koszty
                  obsługi prawnej, a także naprawi wszelkie inne szkody
                  wynikające z wyżej opisanych roszczeń osób trzecich.
                </li>
              </ul>
              <li>6. ROZSTRZYGNIĘCIE KONKURSU</li>
              <ul>
                <li>
                  6.1. W celu wyłonienia zwycięzcy Konkursu Organizator powoła
                  trzyosobową komisję (Jury), w skład której wejdą pracownicy
                  Organizatora oraz Fundatora lub inne wyznaczone przez
                  Organizatora osoby.
                </li>
                <li>
                  6.2. Jury podda przesłane przez Uczestników Zgłoszenia
                  Konkursowe ocenie pod kątem spełniania następujących
                  kryteriów:
                </li>
                <li>6.2.1. kreatywność;</li>
                <li>6.2.2. oryginalność;</li>
                <li>6.2.3. kreowanie pozytywnego wizerunku marki Fundatora;</li>
                <li>6.2.4. pomysłowość.</li>
                <li>
                  6.3. Jury wybierze 3 (słownie: trzy) Zgłoszenia konkursowe,
                  które wskażą poprawną odpowiedź i wykażą się największą
                  oryginalnością.
                </li>
                <li>
                  6.4. Wyniki Konkursu zostaną ogłoszone do 23.12.2022 do
                  godziny 23:59 na profilu Facebook Fundatora lub w komentarzu
                  pod zgłoszeniami zwycięzców.
                </li>
                <li>
                  6.5. Jury zastrzega sobie prawo do nieuzasadniania wyboru
                  Zwycięzców.
                </li>
                <li>
                  6.6. Organizator zastrzega sobie prawo podania do wiadomości
                  publicznej informacji dotyczących przebiegu Konkursu, za
                  pomocą wybranych przez Organizatora mediów społecznościowych,
                  a także na stronie www Fundatora lub Organizatora, na co
                  Uczestnicy wyrażają zgodę.
                </li>
              </ul>
              <li>7. NAGRODY</li>
              <ul>
                <li>7.1. Fundatorem Nagród jest Fundator.</li>
                <li>
                  7.2. W Konkursie każdemu Zwycięzcy zostanie przyznana Nagroda
                  w postaci: fartuch z czapką szefa kuchni, podkładka,
                  bawełniana torba, kalendarz.
                </li>
                <li>
                  7.3. Nagroda należna jest Uczestnikom, których Zgłoszenie
                  Konkursowe zostanie wybrane przez Jury.
                </li>
                <li>
                  7.4. Zwycięzca zostanie powiadomiony o wygranej nie później
                  niż w terminie 2 (dwóch) dni od daty ogłoszenia wyników
                  Konkursu, zgodnie z pkt. 6.3. Regulaminu, za pośrednictwem
                  prywatnej wiadomości w serwisie Facebook lub innych środków
                  komunikacji.
                </li>
                <li>
                  7.5. Zwycięzca zostanie poproszony o podanie swoich danych
                  (imię, nazwisko, adres poczty elektronicznej, adres pocztowy,
                  numer telefonu) w celu umożliwienia Organizatorowi przekazania
                  Nagrody. Nagroda jest przekazywana przez Organizatora w
                  terminie 10 dni od dnia przekazania przez Zwycięzcę wszystkich
                  danych, których żądał Organizator.
                </li>
                <li>
                  7.6. Nagroda nie podlega zamianie na jej równowartość
                  pieniężną ani zamianie na jakikolwiek inne świadczenie.
                </li>
                <li>
                  7.7. Jeśli Zwycięzca nie poda danych, o których mowa w 7.5. w
                  terminie 14 dni od dnia, w którym został o to poproszony lub
                  nie odbierze przekazanej Nagrody w terminie wyznaczonym przez
                  firmę kurierską, Zwycięzca traci prawo do Nagrody, która
                  następnie pozostaje do wyłącznej dyspozycji Organizatora.
                </li>
              </ul>
              <li>8. REKLAMACJE</li>
              <ul>
                <li>
                  8.1. Wszelkie reklamacje dotyczące Konkursu Uczestnicy powinni
                  składać za pośrednictwem poczty tradycyjnej, na adres
                  Purkyńova 2121/3, 110 00 Prague 1, Czech Republic z dopiskiem
                  “Reklamacja”, nie później niż w terminie 14 (czternastu) dni
                  od dnia od dnia ogłoszenia wyników Konkursu zgodnie z pkt.
                  6.3. Regulaminu.
                </li>
                <li>
                  8.2. Reklamacja powinna zawierać: imię, nazwisko, adres e-mail
                  i numer telefonu Uczestnika, jak również dokładny opis i
                  uzasadnienie reklamacji.
                </li>
                <li>
                  8.3. Reklamacje niezawierające danych, o których mowa w pkt
                  8.2 powyżej, skierowane w inny sposób niż określony w pkt 8.1.
                  powyżej lub spóźnione, nie zostaną rozpatrzone.
                </li>
                <li>
                  8.4. Reklamacje rozpatrywane będą przez komisję reklamacyjną
                  powołaną przez Organizatora. Komisja reklamacyjna rozpatrywać
                  będzie reklamacje uczestników na podstawie Regulaminu i
                  obowiązujących przepisów prawa.
                </li>
                <li>
                  8.5. Komisja reklamacyjna rozpatrzy reklamacje złożone zgodnie
                  z pkt 8.1. i 8.2 powyżej w terminie 14 (czternastu) dni od
                  dnia jej złożenia.
                </li>
                <li>
                  8.6. Osoba zgłaszająca reklamację otrzyma decyzję komisji
                  reklamacyjnej na adres e-mail podany w reklamacji.
                </li>
              </ul>
              <li>9. OCHRONA DANYCH OSOBOWYCH I POLITYKA PRYWATNOŚCI</li>
              <ul>
                <li>
                  9.1. Organizator przywiązuje szczególną wagę do poszanowania
                  prywatności Uczestników. Respektując przepisy prawa, a w
                  szczególności RODO Organizator starannie dobiera i stosuje
                  odpowiednie środki techniczne i organizacyjne w celu
                  zapewnienia należytej ochrony przetwarzanych danych osobowych.
                  W szczególności Organizator zabezpiecza dane przed ich
                  udostępnieniem osobom nieupoważnionym, jak również przed ich
                  przetwarzaniem z naruszeniem obowiązujących przepisów prawa.
                  Organizator sprawuje permanentną kontrolę nad procesem
                  przetwarzania danych oraz limituje dostęp do danych w możliwie
                  największym stopniu, udzielając stosownych upoważnień na
                  przetwarzanie tylko wówczas, gdy jest to niezbędne do
                  prawidłowego przeprowadzenia Konkursu.
                </li>
                <li>
                  9.2. Poprzez wzięcie udziału w Konkursie Uczestnik wyraża
                  zgodę na przetwarzanie jego danych osobowych przez
                  Organizatora w celu wskazanym w 9.4.3 poniżej.
                </li>
                <li>
                  9.3. Dane osobowe Uczestników są przetwarzane na podstawie
                  zgody samych Uczestników oraz ustawowych upoważnień
                  zezwalających na przetwarzanie danych osobowych.
                </li>
                <li>
                  9.4. Zgodnie z art. 13 ust. 1 i ust. 2 RODO Organizator
                  informuje, że:
                </li>
                <li>
                  9.4.1. administratorem danych osobowych Uczestników Konkursu
                  jest Organizator;
                </li>
                <li>
                  9.4.2. dane osobowe Uczestników przetwarzane będą na podstawie
                  art. 6 ust 1 pkt a) RODO – w celu przeprowadzenia Konkursu,
                  promowania działalności Fundatora, utrzymywania przez
                  Organizatora kontaktu z Uczestnikiem;
                </li>
                <li>
                  9.4.3. dane osobowe Zwycięzców przetwarzane będą na podstawie
                  art. 6 ust 1 pkt b) i c) RODO – w celu przyznania, wydania i
                  rozliczania Nagród, przeprowadzenia Konkursu oraz w celu
                  wykonania ciążących na Fundatorze obowiązków podatkowych i
                  rachunkowo-księgowych;
                </li>
                <li>9.4.4. odbiorcą danych osobowych Uczestnika będą:</li>
                <li>9.4.4.1. Fundator;</li>
                <li>
                  9.4.4.2. zewnętrzny podmiot prowadzący obsługę informatyczną
                  Organizatora;
                </li>
                <li>
                  9.4.4.3. użytkownicy stron internetowych Organizatora i
                  Fundatora oraz adresaci folderów informacyjnych, materiałów
                  promocyjnych;
                </li>
                <li>
                  9.4.4.4. odbiorcy uprawnieni z mocy prawa do ich otrzymania.
                </li>
                <li>9.4.5. odbiorcą danych osobowych Zwycięzcy będą:</li>
                <li>9.4.5.1. Fundator;</li>
                <li>
                  9.4.5.2. zewnętrzne biuro księgowe prowadzące dla Fundatora
                  dokumentację podatkowo-rachunkowo;
                </li>
                <li>
                  9.4.5.3. zewnętrzny podmiot prowadzący obsługę informatyczną
                  Organizatora;
                </li>
                <li>
                  9.4.5.4. użytkownicy stron internetowych Organizatora i
                  Fundatora oraz adresaci folderów informacyjnych, materiałów
                  promocyjnych;
                </li>
                <li>
                  9.4.5.5. odbiorcy uprawnieni z mocy prawa do ich otrzymania.
                </li>
                <li>
                  9.4.6. Dane osobowe Uczestników nie będą przetwarzane w sposób
                  zautomatyzowany ani poddawane profilowaniu, ani też nie będą
                  wysyłane do krajów trzecich (tj. poza Europejski Obszar
                  Gospodarczy) lub do organizacji międzynarodowych;
                </li>
                <li>
                  9.4.7. Dane Uczestników będą przechowywane przez okres nie
                  dłuższy niż jest to konieczne do realizacji celu ich
                  przetwarzania w postaci przeprowadzenia Konkursu, a następnie
                  zostaną usunięte.
                </li>
                <li>
                  9.4.8. Dane osobowe Zwycięzców będą przechowywane przez okres
                  5 (pięciu) lat podatkowych od dnia powstania obowiązku
                  podatkowego, licząc od końca roku kalendarzowego, w którym
                  nastąpiło nabycie Nagrody;
                </li>
                <li>
                  9.4.9. Uczestnicy posiadają prawo dostępu do treści swoich
                  danych oraz prawo ich sprostowania, usunięcia, ograniczenia
                  przetwarzania, prawo do przenoszenia danych, prawo wniesienia
                  sprzeciwu, prawo do cofnięcia zgody w dowolnym momencie bez
                  wpływu na zgodność z prawem przetwarzania, którego dokonano na
                  podstawie zgody przed jej cofnięciem (o ile prawo takie
                  przysługuje mu na podstawie przepisów prawa). Wykonanie prawa
                  do cofnięcia zgody jest równoznaczne z rezygnacją Uczestnika z
                  udziału w Konkursie;
                </li>
                <li>
                  9.4.10. Uczestnik ma prawo wniesienia skargi do Prezesa Urzędu
                  Ochrony Danych Osobowych, gdy uzna, iż przetwarzanie danych
                  osobowych jego dotyczących narusza przepisy prawa, a w
                  szczególności RODO.
                </li>
                <li>
                  9.5. Zbieranie danych osobowych odbywa się na zasadzie pełnej
                  dobrowolności, jednak podanie danych osobowych stanowi warunek
                  uczestnictwa Konkursie. Uczestnicy przyjmują do wiadomości, że
                  niepodanie danych osobowych (lub podanie fałszywych danych
                  osobowych) może spowodować wykluczenie z Konkursu lub
                  wstrzymanie wydania Nagrody do czasu podania prawidłowych
                  danych osobowych.
                </li>
                <li>
                  9.6. W celach określonych w pkt. 9.4.10. Uczestnicy mogą
                  przesłać stosowne żądanie listem poleconym na adres
                  Organizatora lub za pośrednictwem poczty elektronicznej na
                  adres e-mail: biuro@when.pl.
                </li>
                <li>
                  9.7. Organizator może podjąć środki prowadzące do weryfikacji
                  podanych przez Uczestnika danych osobowych w przypadku
                  powzięcia podejrzenia, że podane dane są nieprawdziwe, lub też
                  powzięcia podejrzenia, iż Uczestnik podejmuje działania
                  naruszające postanowienia Regulaminu lub prawa.
                </li>
                <li>
                  9.8. Na czas trwania Konkursu i 12 (słownie: dwanaście)
                  miesięcy po nim Uczestnik wyraża zgodę na wykorzystywanie
                  przez Organizatora i Fundatora wizerunku Uczestnika
                  utrwalonego w Zgłoszeniu Konkursowym oraz na profilu
                  Uczestnika, Organizatora i Fundatora prowadzonym w serwisie
                  Facebook oraz danych osobowych tam zawartych, w zakresie pól
                  eksploatacji wskazanych w pkt 5.2. Regulaminu, w tym w
                  szczególności w celu umieszczenia ich na stronach
                  internetowych, w folderach informacyjnych, materiałach
                  promocyjnych i profilach w mediach społecznościowych
                  Organizatora.
                </li>
              </ul>
              <li>10. ŚWIADCZENIE USŁUG DROGĄ ELEKTRONICZNĄ</li>
              <ul>
                <li>
                  10.1. W razie świadczenia przez Organizatora usług drogą
                  elektroniczną Regulamin stanowi regulamin, o którym mowa w
                  art. 8. ustawy z dnia 18 lipca 2002 roku o świadczeniu usług
                  drogą elektroniczną (tekst jedn.: Dz.U. z 2019 r. poz. 123) w
                  zakresie tych usług.
                </li>
                <li>
                  10.2. Dla współpracy z systemem teleinformatycznym, którym
                  posługuje się Organizator – Uczestnik musi dysponować sprzętem
                  i systemem spełniającym następujące wymagania techniczne:
                </li>
                <li>
                  10.2.1. urządzenie pozwalające przeglądanie witryn
                  internetowych z dostępem do Internetu;
                </li>
                <li>
                  10.2.2. zaktualizowana przeglądarka internetowa w najnowszej
                  stabilnej wersji dostarczanej przez producenta;
                </li>
                <li>
                  10.2.3. włączona obsługa cookies, a także zainstalowane
                  odpowiednie dodatki do przeglądarki pozwalające na obsługę
                  Java, Javascript, Adobe Flash;
                </li>
                <li>
                  10.2.4. wyłączone oprogramowanie blokujące wykonywanie
                  skryptów powyższych aplikacji;
                </li>
                <li>10.2.5. aktywne konto poczty elektronicznej (e-mail)</li>
                <li>10.2.6. aktywne konto w serwisie Facebook.</li>
                <li>
                  10.3. Zakazane jest, w ramach Konkursu dostarczanie przez
                  Uczestnika treści o charakterze bezprawnym, naruszającym dobra
                  osobiste lub prawa autorskie osób trzecich, lub naruszające w
                  jakikolwiek sposób obowiązujące normy społeczne i obyczajowe,
                  zasady współżycia społecznego w Internecie (netykieta). W
                  szczególności zakazane jest:
                </li>
                <li>
                  10.3.1. propagowanie ustrojów totalitarnych, symboli z nimi
                  związanych, nawoływanie do nienawiści lub popełnienia
                  przestępstwa;
                </li>
                <li>
                  10.3.2. zamieszczanie treści wulgarnych, lub zawierających
                  wulgaryzmy, lub treści powszechnie uznanych za obraźliwe;
                </li>
                <li>
                  10.3.3. atakowanie, zastraszanie i nękanie innych Uczestników;
                </li>
                <li>
                  10.3.4. zamieszczanie i posługiwanie się danymi osobowymi
                  innych osób oraz ich wizerunkami bez stosownego upoważnienia;
                </li>
                <li>10.3.5. dostarczanie szkodliwego oprogramowania;</li>
                <li>
                  10.3.6. zamieszczanie treści propagujących używanie
                  narkotyków, lub substancji psychoaktywnych, nadużywanie
                  alkoholu lub uprawianie hazardu, zawierających nagość lub
                  pornografię oraz drastyczną i nieuzasadnioną przemoc, a także
                  jakichkolwiek innych zachowań, których propagowanie jest
                  zabronione;
                </li>
                <li>
                  10.3.7. działanie o charakterze spamerskim, a także mające
                  znamiona czynu nieuczciwej konkurencji lub nieuczciwej
                  praktyki rynkowej.
                </li>
                <li>
                  10.3.8. zamieszczanie treści podważających dobre imię lub
                  renomę Organizatora, Fundatora lub osób trzecich;
                </li>
                <li>
                  10.3.9. zamieszczanie odnośników do stron i plików
                  zawierających ww. treści;
                </li>
                <li>
                  10.3.10. zamieszczanie odnośników do stron i plików
                  zawierających treści wskazane w pkt. 10.3.1 – 10.3.9 powyżej.
                </li>
                <li>
                  10.4. Uczestnik ponosi odpowiedzialność za treści zamieszczane
                  przez siebie w podczas Konkursu. W przypadku umieszczania
                  przez Uczestnika treści zabronionych prawem lub Regulaminem
                  Organizator upoważniony jest do ich usunięcia, a także do
                  wykluczenia tego Uczestnika z Konkursu.
                </li>
                <li>
                  10.5. Celem uczynienia zadość wymogom ustawy, o której mowa w
                  pkt. 10.1. powyżej, Organizator informuje, iż korzystanie z
                  usług świadczonych drogą elektroniczną za pośrednictwem sieci
                  Internet wiąże się z ryzykiem. Podstawowym zagrożeniem jest
                  możliwość zainfekowania systemu teleinformatycznego przez tzw.
                  wirusy, robaki i konie trojańskie. By uniknąć zagrożeń z tym
                  związanych, zaleca się, by osoby korzystające z sieci Internet
                  zainstalowały na swoim urządzeniu oprogramowanie antywirusowe
                  i na bieżąco je aktualizowały.
                </li>
                <li>
                  10.6. W przypadku umieszczenia w Zadaniu Konkursowym lub na
                  stronie Facebook Fundatora odnośników (linków) do innych stron
                  internetowych, Organizator zastrzega, że nie ponosi
                  odpowiedzialności za treści zamieszczone na tych stronach.
                </li>
                <li>10.7. Organizator nie ponosi odpowiedzialności:</li>
                <li>10.7.1. za treść Zgłoszeń Konkursowych;</li>
                <li>
                  10.7.2. w przypadku braku możliwości kontaktu z Uczestnikiem,
                  z powodu podania przez niego nieprawdziwych lub błędnych
                  danych albo ich niepodania;
                </li>
                <li>
                  10.7.3. w przypadku braku możliwości wydania Nagrody z powodu:
                  błędnego podania przez Zwycięzcę danych, odmowy odebrania
                  Nagrody pod wskazanym przez zwycięzcę adresem bądź
                  nieodebrania przez Zwycięzcę Nagrody pod wskazanym przez
                  Zwycięzcę adresem.
                </li>
              </ul>
              <li>11. POSTANOWIENIA KOŃCOWE</li>
              <ul>
                <li>
                  11.1. Praw oraz obowiązków związanych z uczestnictwem w
                  Konkursie, a także prawa do żądania wydania Nagrody lub
                  wykonania usług będących przedmiotem Nagrody, Uczestnik nie
                  może przenosić na osoby trzecie.
                </li>
                <li>
                  11.2. Powołane w treści Regulaminu bez bliższego oznaczenia
                  numery punktów (pkt) oznaczają odpowiednie jednostki
                  redakcyjne Regulaminu.
                </li>
                <li>11.3. Regulamin podlega wyłącznie prawu polskiemu.</li>
                <li>
                  11.4. W przypadku, w którym Uczestnik nie jest konsumentem w
                  rozumieniu art. 221 ustawy z dnia 23 kwietnia 1964 r. kodeks
                  cywilny (tj. z dnia 10 maja 2018 r., Dz.U. z 2018 r. poz.
                  1025, dalej) sądem właściwym do rozstrzygania sporów jest sąd
                  właściwy miejscowo dla siedziby Organizatora.
                </li>
                <li>11.5. Regulamin wchodzi w życie w dniu 12.12.2022 roku.</li>
              </ul>
            </ul>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ContestTerms;
